@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.switzer
{
    font-family: switzer;
}

.footer-wrap
{
    width: 100vw;
    /* height: 100vh; */
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
    border: 4px #2B2B2B;
    gap: 10vmin;
    color: #494C50;
}

.footer-main
{
    max-width: 100vw;
    width: 100vw;
    background-color: #121212;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    border: 4px #2B2B2B;
    gap: 20vmin;
    color: #494C50;
}

.link-wrap
{
    gap:25vmin;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.contact-me
{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 25vmin;
    /* height: 10vmax; */
    font-size: 1.4vmin;
    font-weight: 300;
    gap: 2vmin;
    /* background-color: #000; */
}

.flex-links
{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between; 
    font-size: 1.4vmin;
    font-weight: 300;
    gap: 0.7vmin;
}

.flex-links a
{
    text-decoration: none;
    font-weight: 500;
    color: #5D6065;
    /* font-size: 1.5vmin; */
}


.flex-links a:hover
{
    color: #7e8186;
}

.footer-button
{
    text-decoration: none;
    font-family: "Inter", serif;
    font-weight: 500;
    font-size: 1.3vmin;
    color: #636363;
    padding: 0.6vmin;
    padding-left: 1.5vmin;
    padding-right: 1.5vmin;
    outline: none;
    background: linear-gradient(#181818, #181818) padding-box,linear-gradient(45deg, #18181800, #121212) border-box,
              linear-gradient(45deg, #2B2B2B, #181818) border-box;
    border-radius: 0.7vmin;
    border: 0.14rem solid transparent;
    box-shadow: 0 0 0 0.1vmin #0a0a0a;
    filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25));
    cursor: pointer;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}


.contact-me span
{
    font-weight: 500;
    color: #5D6065;
    font-size: 1.5vmin;
}

.inputs-footer
{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1vmin;
}

.input-mail
{
    font-size: 1.3vmin;
    padding: 0.8vmin;
    border-radius: 0.7vmin;
    border: none;
    background-color: #1D1D1D;
    color: #949494;
    outline: none;
    font-weight:200;
    height: 100%;
    transition: all 0.4s ease;
}

.input-mail::placeholder
{
    color: #474747;
}

.input-mail:focus
{
    box-shadow: 0 0 0 0.1rem #303030;
}

.footer-detail
{
    font-size: medium;
}

@media only screen and (min-width:768px) {
    
    .link-wrap
    {
        gap:10vmin;
    }

    .footer-button:hover
    {
        color: #aaaaaa;
        box-shadow: 0.1vmin 0.1vmin 0 0.2vmin #0a0a0a;
        transform: scale(0.95);
        filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4));
    }
}


@media only screen and (max-width:1000px) {

    .footer-wrap
    {
        /* height: 100vh; */
        flex-direction: column;
        align-items: center;
        gap: 10vmin;
        padding-bottom: 40vmin;
    }

    .footer-detail
    {
        font-size: 3vmin;
    }

    .footer-main
    {
        flex-direction: column;
        align-items: center;
        gap: 10vmin;
        padding-bottom:5vmin;
    }
    .contact-me
    {
        width: 70%;
        font-size:3.4vmin;
        gap: 3vmin;
    }

    .flex-links
    {
        font-size:3.4vmin;
        gap: 3vmin;
    }

    .contact-me span
    {
        font-size: 3.9vmin;
    }

    .footer-button
    {
        font-size: 3.4vmin;
        padding: 1.5vmin;
        padding-left: 4vmin;
        padding-right: 4vmin;
        border-radius: 1.6vmin;
    }

    .input-mail
    {
        font-size: 3.5vmin;
        padding: 2vmin;
        border-radius: 1.6vmin;
        width: 70%;
    }

    .link-wrap
    {
        width: 70%;
        gap: 20vmin;
        align-items: flex-start;
        justify-content: left;
        flex-direction: row;
        padding: 0;
        /* justify-content: space-between; */
    }

    .link-wrap a
    {
        font-size: 3.9vmin;
    }
    .flex-links a:hover
{
    color: #5D6065;
}
}