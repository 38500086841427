.selector-wrap
{
    z-index: 2;
    top: 0.8rem;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
}

.selector
{
    background-color: #17171A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    /* flex-wrap: wrap; */
    padding: 10px;
    gap: 0.3rem;
    border-radius: 3rem;
    transition: all 0.3s ease-out;
    
}

.pill
{
    outline: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    color: #B0B0B0;
    padding: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 1.5rem;
    background-color: #1F1F2100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width:7rem;
    transition: all 0.3s ease-out;
}

.select-indicator
{
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.4rem;
    background-color: #25993E;
    filter: drop-shadow(0 0 2px #5bcf74);
    box-shadow: inset 0 0 3px #b0ffc1;
}



/* .pill:active
{
    background-color: #1F1F21FF;
    transition: all 0.8s ease-out;
} */

.pill-active
{   
    background-color: #1F1F21FF;
    /* font-weight: 500; */
    transition: all 0.8s ease-out;
    /* transition: font-weight 1.4s ease-out; */
}

@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.switzer
{
    font-family: switzer;
}

@media only screen and (max-width: 1000px) {
    .selector-wrap
    {
        bottom: 1.5rem;
        top: auto;
        /* left: auto; */
        /* right: 2em; */
        /* transform: none;  */
        /* overflow: hidden; */
        filter: drop-shadow(0 0 5px #0b0b0b55);
    }
    .selector
    {
        
        flex-wrap: nowrap;
        flex-direction: row;  
        /* height: 4.5rem; */
        /* width: 6rem; */
        border-radius: 2rem;
    }
    .pill
    {
        display: flex;
        align-items: center;
        text-align: right;
        flex-direction: column-reverse;
        /* border-radius: 3rem; */
        font-size: 3vmin;
        height: 18vmin;
        width: 18vmin;
        transition: all 0.5s;
        padding: 0.5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .pill:hover
{
    background-color: #1F1F21FF;
    /* font-weight: 500; */
    /* transition: font-weight 0.3s ease-out; */
}
    
}

@media only screen and (max-width: 1500px) and (min-width: 800px) {
    .selector
    {
       transform: scale(0.85);
    }
}