@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.hero
{
    max-width: 100%;
    width: 100vw;
    /* height: 100vh; */
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 14vmin;
}

.head-bar
{
    display: flex;
    align-items:end;
    justify-content: space-between;
    width: 100%;
    gap:0.3rem;
    z-index: 2;
}

.location-svg
{
    width: 25%;
}

.speaker-svg
{
    width: 20%;
}

.BG-svg
{
    width: 20%;
}

.hero-holder
{
    width: 50vmin;
    /* background-color: rgb(145, 145, 145); */
    height: 42vmin;
    margin-top: 20vmin;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap:2.2vmin;
}


.hero-screen
{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 75%;
    background-color: #2F1307;
    border-radius: 2vmin;
    filter: drop-shadow(0.2rem 0.2rem 0.4rem #1C1C1C) drop-shadow(-0.2rem -0.2rem 0.4rem #050505);
    box-shadow: 0.5rem 0.5rem 1.5rem inset #00000088 ;
}

.hero-grid
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.smiley
{
    height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    filter: drop-shadow(0 0 0.3rem #A6693644) drop-shadow(0 0 0.6rem #EB893755) drop-shadow(0 0 0.8rem #FF740066);
}

.bg-glow
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 100%;
    background-color: #68280E;
    width: 80%;
    height: 80%;
    filter: blur(8vmin);

}

.hero-scroll
{
    font-family: "Kode Mono", serif;
    font-weight: 500;
    font-size: 1.5vmin;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap:1rem;
}

.down-svg
{
    width: 6vmin;
}

.social-svg
{
    transform: scale(1.2);
}
.social-svg path
{
    transition: all 0.4s;
    fill:#636363;
    
    overflow: visible;
}


.behance:hover path
{
    fill:#417BEA;
}

.dribbble:hover path
{
    fill:#BB6989;
}

.linkedin:hover path
{
    fill:#3C8AD8;
}

.hero-buttons
{
    
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.arrow-button
{
    font-family: "Inter", serif;
    font-weight: 500;
    font-size: 1.3vmin;
}

.base-button
{
    text-decoration: none;
    font-size: 1.3vmin;
    font-family: "JetBrains Mono", serif;
    color: #636363;
    padding: 0.8vmin;
    padding-left: 1.5vmin;
    padding-right: 1.5vmin;
    outline: none;
    background: linear-gradient(#181818, #181818) padding-box,linear-gradient(45deg, #18181800, #121212) border-box,
              linear-gradient(45deg, #2B2B2B, #181818) border-box;
    border-radius: 0.5rem;
    border: 0.14rem solid transparent;
    box-shadow: 0 0 0 0.1vmin #0a0a0a;
    filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25));
    cursor: pointer;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}



.button-socials
{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    height: 100%;
    padding: 1vmin;
}

.social-container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
}

.scroll-text
{
  background: -webkit-linear-gradient(#3C3C3F, #373738);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-borders
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 93%;
    height: 90%;
    padding: 8rem;
    
}

.border-one
{
    width: 98%;
    height: 5%;
    display: flex;
    padding-left: 1vmin;
    padding-right: 1vmin;
    align-items: center;
    justify-content: space-between;
}

.border-two
{
    width: 97%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
}

.border-vertical-line
{
    width: 0.2vmin;
    height: 94%;
    border-radius: 0.2vmin;
    background-color: #FFAE5C;
}

.border-dot
{
    width: 0.5vmin;
    height: 0.5vmin;
    border-radius: 0.5vmin;
    background-color: #FFAE5C;
}

.border-line
{
    width: 90%;
    height: 0.2vmin;
    border-radius: 0.2vmin;
    background-color: #FFAE5C;
}

.element-glow
{
    box-shadow: 0 -0.1rem 0.1rem inset #FFD2A4 ;
    filter: drop-shadow(0 0 0.3rem #A66936) drop-shadow(0 0 1rem #A66936);
}

@media only screen and (max-width:900px) {
.hero
{
    gap: 12vmax;
    height: 90vh;
}
.hero-buttons
{
    font-size: 2vmax;
}
.base-button
{
    padding: 1vmax;
    font-size: 1.5vmax;
}

.arrow-button
{
    font-size: 1.4vmax; 
}
.hero-screen
{
border-radius: 0.9rem;
}
.hero-holder
{
    /* padding-top: 30vmin; */
    /* padding-bottom: 25vmin; */
    margin-top:-1vh;
    width: 80vmin;
    height: 40vmax;
    gap: 2vmax;
}
.hero-scroll
{
    font-size: 2vmax;
}
.location-svg
{
    width: 30%;
}
.speaker-svg
{
    width: 20%;
}
.BG-svg
{
    width: 30%;
}
.hero-grid
{
    height: 100%;
}

.border-dot
{
    width: 1.5vmin;
    height: 1.5vmin;
    border-radius: 1.5vmin;
}

.border-vertical-line
{
    width: 0.4vmin;
}

.border-line
{
    height: 0.4vmin;
}

.hero-borders
{
    width: 95%;
}

.down-svg
{
    width: 7vmax;
}

}

@media only screen and (min-width:768px) {
    .hero-grid
    {
        width: 100%;
    }
    .base-button:hover
{
    color: #aaaaaa;
    box-shadow: 0.1vmin 0.1vmin 0 0.2vmin #0a0a0a;
    transform: scale(0.95);
    filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4));
}
}

@media only screen and (max-width: 1500px) and (min-width: 900px) {
    .hero-holder
    {
       transform: scale(1.2);
    }
    .hero
    {
        gap:20vmin;
    }
    
}