@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.switzer
{
    font-family: switzer;
}

.Projects
{
    max-width: 100%;
    min-height: 100vh;
    width: 100vw;
    /* height: 100vh; */
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
    gap:5vmin;
}

.title-section
{
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1vmax;
}

.titles
{
 color: #A9A7A7;
 font-size:xx-large;
 font-weight: 400;
 text-align: center;
}

.title-section hr {
    width: 100%;
    height: 2px;
    background-color: #5A5A5A;
    border: none;
    margin: 8px auto;
}

.rive-elements
{
    width: 80vmin;
    height: 60vmin;
    /* background-color: #c4c4c4; */
    filter: drop-shadow( 0 0 10px #000);
}

.grid-container
{
    width: 100%;
    height: 100%;
    display: grid;
    gap: 2vmin;
    place-content: center;
}

.grid-container {
    grid-template-columns: 80vmin;
}

/* Desktop view - 2x2 grid */
@media (min-width: 1040px) {

    .title-section{
        width: 85vmin;
    }
    .Projects
    {
       padding-top: 0;
       padding-bottom: 0;
    }

    .rive-elements
    {
        width: 40vmin;
        height: 30vmin;
        /* background-color: #c4c4c4; */
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .rive-elements:hover
    {
        transform: scale(1.05);
    }

    .grid-container 
    {
        gap: 5vmax;
        grid-template-columns: repeat(2, 40vmin);
    }
}

