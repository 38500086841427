@font-face {
  font-family: switzer;
  src: url(../../fonts/Switzer-Variable.ttf);
}

.Hobby
{
    background-color: #0C0C0D;
    max-width: 100%;
    min-height: 100vh;
    width: 100vw;
    /* height: 100vh; */
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
    gap:10vmin;
}

.grid-post-container {
    
    display: grid;
    gap: 10vmin;
    grid-template-columns: 80vmin;
    background-color: #0C0C0D;
  }

  .grid-item {
    width: 80vmin;
    height: 80vmin;
    border-radius: 3vmax;
    background-color: #1C1C1C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .duration
  {
    font-size: 2vmax;
    text-transform: uppercase;
    color: #575757;
  }

  .hobby-title
  {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    font-size: 3.3vmax;
    color: #BFBFBF;
    gap: 1vmin;
  }

  .sub-title
  {
    font-size: 2vmax;
    color: #A3A3A3;
    font-weight: 300;
  }

  .content
  {
      width: 80vmin;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      gap:5vmin;
      color: #A3A3A3;
      font-family: switzer;
  }
    
  .left-flex
  {
      width: 80vmin;
      height: 100%;
      gap: 3vmin;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
  }

  .right-flex
  {
      width: 80vmin;
      text-wrap: wrap;
      font-weight: 300;
      font-size: 2vmax;
      line-height: 3vmax;
      color: #949494;
  }

  
  .image-holder
  {
      width: 75%;
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

    .image-holder img
  {
      width: 100%;
  }

  .hobby-navigation
  {
    width: 80vmin;
    font-family: switzer;
    font-size: x-large;
    color: #BFBFBF ;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4vmin;
  }

  .hobbies-list
  {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2vmin;
  }

  .hobby-navigation button
  {
    cursor: pointer;
    padding: 3vmin;
    border-radius: 6vmin;
    font-size: medium;
    border: none;
    background-color: #858585;
  }
 
  /* Mobile devices (default: 1 column) */
  /* No additional rules needed as the default is already 1 column */
  
  /* Tablets (2 columns) */
  @media screen and (min-width: 768px) {

    .Hobby
    {
      /* height: 100vh; */
      gap:5vmin;
      padding-top: 10vmin;
    }

    .grid-post-container {
      gap: 2vmin;
      grid-template-columns: repeat(2, 45vmin);
    }
    .grid-item {
      width: 45vmin;
      height: 45vmin;
      border-radius: 3vmax;
      background-color: #1C1C1C;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .content
    {
      width: 92vmin;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      color: #A3A3A3;
      font-family: switzer;
    }
    .left-flex
    {
      width: 94vmin;
      height: 100%;
      gap: 3vmin;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .right-flex
    {
      width: 94vmin;
      text-wrap: wrap;
      font-weight: 300;
      font-size: 2.5vmin;
      line-height: 2.9vmin;
      color: #949494;
    }

    .duration
    {
      font-size: 1.6vmin;
      text-transform: uppercase;
      color: #575757;
    }
  
    .hobby-title
    {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      font-size: 3.5vmin;
      color: #BFBFBF;
      gap: 1vmin;
    }
  
    .sub-title
    {
      font-size: 1.4vmin;
      color: #A3A3A3;
      font-weight: 300;
    }

    .hobby-navigation
  {
    width: 90vmin;
    font-family: switzer;
    font-size: x-large;
    color: #BFBFBF ;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1vmin;
  }

  .hobbies-list
  {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1vmin;
  }

  .hobby-navigation button
  {
    cursor: pointer;
    padding: 1vmin;
    border-radius: 2vmin;
    font-size: large;
    border: none;
    background-color: #858585;
  }

  }
  
  /* Desktop (3 columns) */
  @media screen and (min-width: 1040px) {

    .hobby-navigation
    {
      width: 52vmax;
    }

    .Hobby
    {
      padding-top: 15vmin;
      padding-bottom: 10vmin;
      gap:10vmin;
    }

    .grid-post-container {
      /* width: 60%; */
      gap: 2vmax;
      grid-template-columns: repeat(3, 16vmax);
    }
    
    .content
    {
      width: 52vmax;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      gap: 20px;
      padding: 20px; 
      color: #A3A3A3;
      font-family: switzer;
    }
    .grid-item 
    {
      width: 16vmax;
      height: 16vmax;
      border-radius: 2vmax;
      background-color: #1C1C1C;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .left-flex
    {
      width: 40%;
      height:inherit;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .right-flex
    {
      width: 40%;
      text-wrap: wrap;
      font-weight: 300;
      font-size: 1.7vmin;
      line-height: 2.5vmin;
      color: #949494;
    }

    .duration
    {
      font-size: 1.6vmin;
      text-transform: uppercase;
      color: #575757;
    }
  
    .hobby-title
    {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      font-size: 3.5vmin;
      color: #BFBFBF;
      gap: 1vmin;
    }
  
    .sub-title
    {
      font-size: 1.4vmin;
      color: #A3A3A3;
      font-weight: 300;
    }

    img
    {
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .grid-item:hover img
    {
      
      transform: scale(1.05);
    }

    .right-flex a
  {
    color: lightblue;  
    opacity: 40%;
  }

  .right-flex a:hover
  {
    opacity: 100%;
  }

  .hobby-navigation button:hover
  {
    background-color: #bfbfbf;
  }
  }