@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.viewer-holder
{
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewer
{
    width: 60vmin;
    height: 65vmin;
    background-color: #0D0D0D;
    border-radius: 3vmin;
    border: solid 1px #292929;
    overflow: hidden;
    z-index: 1;
}

.close-area
{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000000CC;
}

.view-area
{
    height: 80%;
    width: 100%;
    background-color: #101010;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.view-details
{
    height: 10%;
    /* width: 100%; */
    background-color: #0D0D0D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5%;
}

.view-titles
{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    gap: 10%;
}

.item-title
{
    font-family: switzer;
    font-size: xx-large;
    color: #969696;
}

.item-duration
{
    font-family: "JetBrains Mono";
    font-size: larger;
    color: #3D3D3D;
}

.item-image
{
    width: 60%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.item-image img
{
    width: 100%;
}

.slide-button
{
    font-family: switzer;
    width: 5vmin;
    height: 5vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5vmin;
    border: none;
    background-color: #232323;
    cursor: pointer;
    margin: 5%;
}


@media screen and (max-width: 1024px) {

    .item-image
    {
        width: 60%;
        height: 60%;
    }
    
    .viewer
    {
        width: 80vmin;
        height: 80vmin;
    
    }
}

@media screen and (max-width: 768px) {


    .item-image
    {
        width: 70%;
        height: 70%;
        top: 42%;
    }

    .viewer
    {
        width: 90vmin;
        height: 110vmin;
    
    }

    .view-area
    {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .slide-button
    {
        margin: 2%;
        margin-bottom: 5%;
        padding: 5%;
    }
    .item-title
    {
        font-family: switzer;
        font-size: x-large;
        color: #969696;
    }

}