@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.switzer
{
    font-family: switzer;
}

.about-wrap
{
    max-width: 100%;
    /* min-height: 100vh; */
    padding-top: 20vmin;
    padding-bottom: 20vmin;
    width: 100vw;
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:5vmin;
}

.about
{
    
    text-align: left;
    color: #8E8F90;
    width: 80%;
    font-weight: 300;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 10vmin;
}

.about-left
{
    width: 100%;
    font-size:3vmax;
}

.about-right
{
    font-size:2.5vmax;
}

.about-contact
{
    color: #5b5b5c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "JetBrains Mono", serif;
    font-size:2vmax;
    gap: 1vmax;
}

.about span
{
    color: #D8D9D9 ;
}

.about hr {
    width: 40%;
    height: 2px;
    background-color: #252525;
    border: none;
    margin: 0px;
}


.title-section
{
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1vmax;
}

.titles
{
 color: #A9A7A7;
 font-size:xx-large;
 font-weight: 400;
 text-align: center;
}

.title-section hr {
    width: 100%;
    height: 2px;
    background-color: #5A5A5A;
    border: none;
}


@media (min-width: 1040px) {

    .title-section{
        width: 85vmin;
    }

    .about
    {
    width: 85vmin;
    flex-direction: row;
    align-items: flex-start;
    gap:0;
    }
    .about-left
    {
    font-size: 2.5vmin;
    width:45%;
    /* background-color: #5A5A5A; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    }

    .about-right
    {
    width: 40%;
    font-size: 1.8vmin; 
    line-height: 150%;
    }

    .about-contact
    {
        font-size: 1.5vmin; 
        gap: 1vmin;
    }

    .about hr {
        width: 0;
        height: 0;
        visibility: none;
    }
}