@font-face {
    font-family: switzer;
    src: url(../fonts/Switzer-Variable.ttf);
}

.switzer
{
    font-family: switzer;
}

.Hobbies
{
    max-width: 100%;
    min-height: 100vh;
    width: 100vw;
    /* height: 100vh; */
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
    gap:10vmin;
}

.rive-hobbies
{
    /* background-color: #A9A7A7; */
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 80vmin;
    height: 70vmin;
    color: #A9A7A7;
    /* gap: 1vmin; */
    font-size:5vmin;
    text-decoration: none;;
}

.grid-container
{
    width: 100%;
    height: 100%;
    display: grid;
    gap: 5vmin;
    place-content: center;
}

.grid-container {
    grid-template-columns: 80vmin;
    gap: 10vmin
}


@media (min-width: 1040px) {
    .Hobbies
    {
        padding-top: 0;
        padding-bottom: 0;
        gap: 4vmin;
    }
    .title-section{
        width: 85vmin;
    }
    .grid-container 
    {
        gap: 5vmin;
        grid-template-columns: repeat(2, 40vmin);
    }
    .rive-hobbies
    {
        width: 40vmin;
        height: 33vmin;
        gap: 1vmin;
        font-size:x-large;
    }
}